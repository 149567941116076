import {QRCodeSVG} from 'qrcode.react';

import styles from './QRCode.module.css'

const QRCode = ({ url }) => {
  return (
    <div className={styles.wrapper}>
      <QRCodeSVG value={url} size={200} />
    </div>
  )
}

export default QRCode;
