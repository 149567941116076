export const getSliderSettings = (type = 'mobile') => {
  const sliderSettings = {
    mobile: {
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    }
  }

  return sliderSettings[type];
}
