import Card from "./Card";

import styles from './CardList.module.css';

const CardList = ({ cards, type, lastElementRef }) => {
  return <div className={styles.cards}>
    {cards.map((card, index) =>
      <Card  key={`${card.account_id}${card.thank_id ? `_${card.thank_id}`: ''}`} {...card} type={type} isLastElement={cards.length === index + 1} lastElementRef={lastElementRef} />
    )}
  </div>
}

export default CardList;
