import {useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HomePage from "../HomePage/HomePage";
import DonationsPage from "../DonationsPage/DonationsPage";
import ThanksPage from "../ThanksPage/ThanksPage";
import ProfilePage from "../ProfilePage/ProfilePage";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import NavSliderBtn from "../../components/NavSliderBtn/NavSliderBtn";

import { fetchDonations, fetchThanks, fetchUser } from "../../store/actions";

import routesConfig from "../../routes/routes";

import ScrollTop from "../../hoc/ScrollTop";

import { USED_DATA_LS } from "../../constants/api";

import { getUrlParam } from "../../utils/URL";
import { getLocalStorage } from "../../utils/localStorage";

import bgImage from './img/bg.png';

import styles from './App.module.css';

const pathNames = ['/', '/donations', '/thanks'];

function App() {
  const slickRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch()

  const { donated } = useSelector(({ user }) => user);

  useEffect(() => {
      const { devid } = getLocalStorage(USED_DATA_LS);
      const reflink = getUrlParam(window.location.href, 'ref');
      dispatch(fetchUser(devid, reflink));
      dispatch(fetchDonations());
      dispatch(fetchThanks());
  }, [])

  useEffect(() => {
    const index = pathNames.indexOf(location.pathname);
    if (~index) {
      setTimeout(() => {
        slickRef.current.slickGoTo(index, false);
      }, 4);
    }
  }, [location]);

  const afterChange = (i) => {
    window.history.pushState(null, null, pathNames[i]);
  }

  return (
    <div className={styles.app}>
      <Header/>
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={bgImage} alt=""/>
        </div>
        <ScrollTop>
          {pathNames.includes(location.pathname) &&
            <Slider afterChange={afterChange} adaptiveHeight={true} speed={0} ref={slickRef} className='pageSlider'
                    nextArrow={<NavSliderBtn position='right'/>} prevArrow={<NavSliderBtn position='left'/>}>
              {donated ? <ProfilePage /> : <HomePage/>}
              <DonationsPage/>
              <ThanksPage/>
            </Slider>}
          <Routes>
            {routesConfig.map(({path, exact, element}, index) => {
              return <Route key={index} path={path} exact={exact} element={element} />
            })}
          </Routes>
        </ScrollTop>
        <Footer/>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
