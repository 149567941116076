/*
Format CSS-variable:
--theme-default-uniqueName # default variable
--theme-light-uniqueName   # for "light"
--theme-dark-uniqueName    # for "dark"
*/

export const changeCssVariables = (theme) => {
  const root = document.querySelector(':root');

  const cssVariables = ['body', 'header', 'button', 'button-color', 'buttonBg', 'buttonApp', 'drawerBg', 'text', 'appBg', 'filter', 'modalBG', 'card-background', 'card-border-color', 'card-wrapper-background', 'card-translate-color'];

  cssVariables.forEach((element) => {
    root.style.setProperty(
      `--theme-default-${element}`,
      `var(--theme-${theme}-${element})`
    );
  })
}
