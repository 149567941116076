import {Link} from "react-router-dom";
import cn from 'classnames';
import {useTranslation} from "react-i18next";

import closeIcon from './img/close.svg'

import styles from './Drawer.module.css'
import {useSelector} from "react-redux";


const Drawer = ({isShow, onClose}) => {
  const { t } = useTranslation();

  const { donated } = useSelector(({ user }) => user);

  const handleClose = () => {
    onClose(false);
  }

  return (
    <div className={cn(styles.backdrop, isShow ? styles.backdropShow : '')} onClick={handleClose}>
      <div className={cn(styles.drawer, isShow ? styles.drawerShow : '')} onClick={(e) => e.stopPropagation()}>
        <div className={styles.drawerHeader}>
          <img src={closeIcon} alt="" width='30' onClick={handleClose} />
        </div>
        <ul className={styles.drawerContent} onClick={handleClose}>
          <li>
            <Link to='/'>{t('Home')}</Link>
          </li>
          <li>
            <Link to='your-help'>{t('Helps')}</Link>
          </li>
          <li>
            <Link to='/thanks'>{t('Thanks')}</Link>
          </li>
          {!donated &&
            <li>
              <Link to='/profile'>{t('My cabinet')}</Link>
            </li>
          }
        </ul>
      </div>
    </div>
  );
}

export default Drawer;
