import {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
// import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";

import UiButton from "../ui/UiButton/UiButton";
import UiSwitch from "../ui/UiSwitch/UiSwitch";
import UiCheckbox from "../ui/UiCheckbox/UiCheckbox";

import {sendForm} from "../../utils/sendForm";
import {toastMessage} from "../../utils/toastMessage";
import {setLocalStorage} from "../../utils/localStorage";
import {ORDER_DATA_LS} from "../../constants/api";

import styles from './PaymentForm.module.css'
import ModalContext from "../../context/Modal/ModalContext";

const amountOnce = ['30', '50', '100'];
const amountRegular = ['200', '300', '500'];

const PaymentForm = ({accountId, subscribe = true, buttonSubmitText = "I'm helping" }) => {
  const {openModal} = useContext(ModalContext);
  const {id, sid} = useSelector((state) => state.user);

  const { t } = useTranslation()
  // const navigate = useNavigate()
  const [amount, setAmount] = useState('20');
  const [checked, setChecked] = useState(subscribe);
  const [paymentData, setPaymentData] = useState({});
  const [rule, setRule] = useState(false);

  useEffect(() => {
    setLocalStorage(ORDER_DATA_LS, paymentData);
  }, [paymentData]);

  // const handleNavigate = () => {
  //   navigate('/payment', {
  //     state: {
  //       amount,
  //       accountId,
  //       subscribe: checked
  //     }
  //   })
  // }

  const onSwitch = () => {
    setChecked((prevState) => {
      setAmount(!prevState ? '20' : '100')
      return !prevState;
    });
  }

  const onPayment = async () => {
    try {
      const data = { id, sid, amount, currency_id: 978, periodic: checked };
      if (accountId) {
        data.recipient = accountId
      }
      const results = await axios.post('/api/pay-donate', data);

      setPaymentData((prevState) => ({...prevState, orderId: results.data.order_id}))

      if (results.data.post_url) {
        sendForm(results.data.payment, results.data.post_url);
      }
    } catch (e) {
      const errorMessage = e.response?.data?.message || e.response?.data?.error || e.message
      toastMessage('error', t(errorMessage))
    }
  }

  const onShowRule = () => {
    openModal({
      children: <p>{t('rules')}</p>,
      okSubmit: () => setRule(true),
      cancel: t('No'),
      ok: t('I agree'),
    })
  }

  return (
    <div className={styles.form}>
      <div className={styles.formWrapper}>
        <UiSwitch checked={checked} onChange={onSwitch} id='subscribe' text={t('Once')} textChecked={t('Regularly')}/>
        <span className={styles.infoText}>{t('_your_help_info')}</span>
        <CurrencyInput
          value={amount}
          suffix='€'
          groupSeparator=' '
          style={{width: '100%'}}
          onValueChange={(value) => setAmount(value)}
          allowDecimals={false}
        />
        <div className={styles.smallBtns}>
          {(!checked ? amountRegular : amountOnce).map((v, i) => <button key={`${v}_${i}`}
                                                                         onClick={() => setAmount(v)}>{v} €</button>)}
        </div>
        <div className={styles.rules}>
          <UiCheckbox text={t('I agree to')} onChange={() => setRule(!rule)} checked={rule}/><span
          className={styles.rule} onClick={onShowRule}>{t('the terms')}</span>
        </div>
      </div>
      <UiButton color='primary' text={t(buttonSubmitText)} disabled={!(rule && amount >= 1)} onClick={onPayment}/>
    </div>
  )
}

export default PaymentForm;
