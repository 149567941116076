import expandMoreIcon from './img/expand_more.svg'

import styles from './UiSelect.module.css';

const UiSelect = ({options, optionsTranslate, onChange, selected = 0, labelText = '', required, currentValue}) => {
  const requiredStar = required && (<span style={{color: 'red'}}>*</span>);

  return (
    <label className={styles.label}>
      {labelText && <span className={styles.labelText}>{labelText}: {requiredStar}</span>}
      <div className={styles.labelWrapper}>
        <select onChange={onChange} className={styles.labelSelect} value={currentValue || options[selected]}>
          {options.map((option, i) =>
            <option
              value={option}
              key={option}
              disabled={option === 'choose_option'}
            >
              {optionsTranslate[i]}
            </option>
          )}
        </select>
        <img src={expandMoreIcon} className={styles.labelIcon} alt=""/>
      </div>
    </label>
  );
}

export default UiSelect;
