import {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
import cn from 'classnames';

import PaymentForm from "../../components/PaymentForm/PaymentForm";

import {getSliderSettings} from "../../utils/getSliderSettings";

import styles from './TargetHelpPage.module.css'

const TargetHelpPage = () => {
  const { t, i18n } = useTranslation();
  const [help, setHelp] = useState();
  const { helpId } = useParams();
  useEffect(() => {
    (async () => {
      if (!helpId || helpId === 'null') return;
      const response = await axios.post('/api/get-demand', {account_id: helpId })
      setHelp(response.data)
    })()
  }, [helpId, i18n.language])

  return (
    <>
      {help && <div className={styles.wrapper}>
        <h2 className={cn('title', styles.title)}>{help.title || 'Should be a title'}</h2>
        <span className={styles.infoTitle}>{help.message}</span>
        {help.photos && help.photos.length > 0 && <Slider {...getSliderSettings()} className='photoSlider'>
          {help.photos.map((photo, i) => {
            return <img key={`${i}_image`} src={`/get-photo?photo_id=${photo}`} alt="" style={{width: '100%'}}/>
          })}
        </Slider>}
        <span className={styles.subTitle}>{t('Help directly')}</span>
        <PaymentForm accountId={helpId}/>
      </div>}
    </>
  )
}

export default TargetHelpPage;
