import {Link} from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import ShareOther from "../../components/ShareOther/ShareOther";

import styles from './HomePage.module.css'
import Tagline from "../../components/Tagline/Tagline";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.title__block}>
        <Tagline />
        <Link to='/your-help'>{t('wantHelp')}</Link>
      </div>
      <ShareOther showCryptoWallet={false}/>
    </div>
  )
}

export default HomePage;
