import cn from 'classnames';

import styles from './UiTextarea.module.css'

const UiTextarea = ({ placeholder, id, onChange, onBlur, value, labelText, required = false, isError }) => {
  const requiredStar = required && (<span style={{color: 'red'}}>*</span>)
  return (
    <label className={cn(styles.label, isError ? styles.error : '')} htmlFor={id}>
      {labelText && <span>{labelText} {requiredStar}</span>}
      <textarea id='id' onChange={onChange} value={value} placeholder={placeholder} onBlur={onBlur}/>
    </label>
  )
}

export default UiTextarea;
