import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";

import UiInput from "../../components/ui/UiInput/UiInput";
import UiButton from "../../components/ui/UiButton/UiButton";

import { useInput } from "../../hooks/useInput";

import { ADD_INFO_USER } from "../../store/types";
import { toastMessage } from "../../utils/toastMessage";

import styles from './PayReturnPage.module.css';

const PayReturnPage = ({ status }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const phone = useInput('+', { isEmpty: false, isPhone: true });
  const email = useInput('', { isEmpty: false, isEmail: true });
  const dispatch = useDispatch()

  const {id, sid, donated } = useSelector((state) => state.user);

  const backToProfileHandler = () => {
    navigate(donated ? '/' : '/profile')
  }
  const updateUserHandler = () => {
    const emailValue = email.value.trim();
    const phoneValue = phone.value.trim();
    if (emailValue.length && email.errorMessage) {
      toastMessage('warn', t('_wrong_email'))
      return;
    }

    if (phoneValue.length !== 1 && phone.errorMessage) {
      toastMessage('warn', t('_wrong_phone'))
      return;
    }

    const data = {};

    if (emailValue.length) {
      data.email = emailValue;
    }

    if (phoneValue.length > 1) {
      data.phone = phoneValue;
    }

    axios.post('/api/update-profile', { id, sid, ...data }).then((results) => {
      dispatch({
        type: ADD_INFO_USER,
        payload: data
      })
    })
      .then(() => {
        toastMessage('success', t('_data_save'));
        setTimeout(() => navigate('/'), 3000);
      })
      .catch((e) => {
      const errorMessage = e.response?.data?.message || e.response?.data?.error || e.message;
      console.log('Error', errorMessage)
      toastMessage('error', t(errorMessage));
    })
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t(`_payment_${status}`)}</h2>
      <Trans i18nKey={`_payment_${status}_info`} components={{ p: <p/> }}></Trans>
      {status === 'success' && (
        <div className={styles.successForm}>
          <UiInput
            labelText={t('Email')}
            type='email'
            id='email'
            placeholder={t('Enter your email')}
            value={email.value}
            onChange={(e) => email.onChange(e)}
            onBlur={(e) => email.onBlur(e)}
            isError={!email.isEmpty && email.isDirty && email.errorMessage}
          />
          {(!email.isEmpty && email.isDirty && email.errorMessage) && <span className={styles.errorInput}>{email.errorMessage}</span>}
          <UiInput
            labelText={t('Contact number')}
            type='tel'
            id='phone'
            placeholder={t('Enter your phone')}
            value={phone.value}
            onChange={(e) => phone.onChange(e)}
            onBlur={(e) => phone.onBlur(e)}
            isError={phone.value.length > 1 && !phone.isEmpty && phone.isDirty && phone.errorMessage}
          />
          {(phone.value.length > 1 && !phone.isEmpty && phone.isDirty && phone.errorMessage) && <span className={styles.errorInput}>{phone.errorMessage}</span>}
        </div>
      )}
      <UiButton text={t(status === 'success' ? '_save' : 'Back to profile')} onClick={status === 'success' ? updateUserHandler : backToProfileHandler}/>
    </div>
  )
}

export default PayReturnPage;
