import {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";

import UiTextarea from "../../components/ui/UiTextarea/UiTextarea";
import UiInput from "../../components/ui/UiInput/UiInput";
import UiButton from "../../components/ui/UiButton/UiButton";

import {toastMessage} from "../../utils/toastMessage";

import imgUpload from "./img/file_upload.svg";

import styles from './NewThankPage.module.css';

const NewThankPage = () => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [disable, setDisable] = useState(false);
  const [success, setSuccess] = useState(false);
  const [photo, setPhoto] = useState(null);

  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!(link.length && message.length)) {
      toastMessage('warn', t('Fill in all the fields!'))
      return;
    }

    setDisable(true);

    try {
      const formData = new FormData();
      formData.append('id', user.id);
      formData.append('sid', user.sid);
      formData.append('link', link.trim());
      formData.append('message', message.trim());
      if (photo) {
        formData.append('photo', photo);
      }

      await axios.post('/api/new-thank', formData);

      setSuccess(true);
    } catch (e) {
      console.log(e)
      const errorMessage = e.response?.data?.message || e.response?.data?.error || e.message;
      toastMessage('error', errorMessage);
    } finally {
      setDisable(false);
    }
  }

  return (
    <div className={styles.wrapper}>
      {success ?
        <>
          <h2 className={styles.title}>{t('Thank you! After verification, thanks will be published.')}</h2>
          <UiButton text={t('Go home')} onClick={() => navigate('/thanks')}
                    style={{width: '100%', marginTop: '40px'}}/>
        </>
        :
        <>
          <div className={styles.formWrapper}>
            <h2 className={styles.title}>{t('_new_thank_text')}</h2>
            <UiTextarea
              id='message'
              labelText={t("Your impressions of the Fund's work")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required={true}
            />

            <UiInput
              labelText={t('_link_to_social')}
              id='link'
              placeholder={t('Link to social media')}
              value={link}
              onChange={(e) => setLink(e.target.value.trim())}
              required={true}
            />

            {photo && <img src={URL.createObjectURL(photo)} alt="" style={{width: '100%'}}/>}

            <label htmlFor='files' className={styles.inputFiles}>
              <input id='files' type="file" accept="image/*" onChange={({target}) => {
                setPhoto(target.files[0])
              }
              }/>
              <img src={imgUpload} alt=""/>
              {photo ? <span>{t('Chosen {count} file(s)', {count: 1 })}</span> :
                <span>{t('Upload')}</span>}
            </label>

            <UiButton text={t('Send')} color='primary' disabled={disable} onClick={handleSubmit}/>
          </div>
        </>
      }
    </div>
  )
}

export default NewThankPage
