import {useSelector} from 'react-redux'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import PaymentForm from "../../components/PaymentForm/PaymentForm";
import CardList from "../../components/CardList/CardList";
import ShareOther from "../../components/ShareOther/ShareOther";
import UiButton from "../../components/ui/UiButton/UiButton";

import styles from './YourHelpPage.module.css'

const YourHelpPage = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const demands = useSelector((state) => state.donations?.demands?.slice(0, 3));

  const handleMoveToAllHelps = () => {
    navigate('/donations')
  }

  return (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('_your_help_title')}</h2>
            <PaymentForm subscribe={true} buttonSubmitText={'Pay now'}/>
        </div>
        <div className={styles.targetBlock}>
            <span className={styles.infoTitle}>{t('Direct help')}</span>
            {demands && <CardList cards={demands} type='donations'/>}
            <UiButton text={t('View all')} onClick={handleMoveToAllHelps}/>
        </div>
        <ShareOther showCryptoWallet={false} />
    </div>
  )
}

export default YourHelpPage;
