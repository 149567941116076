import {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import ModalContext from "../../context/Modal/ModalContext";

import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import Drawer from "../Drawer/Drawer";

import logo from './img/logo.png';
import menu from './img/menu.svg';

import styles from './Header.module.css';
import Lang from "../Lang/Lang";

const Header = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const {openModal} = useContext(ModalContext);

  const navigate = useNavigate();

  const [isShowDrawer, setIsShowDrawer] = useState(false)

  const handleModal = () => {
    if (user.received === undefined) {
      navigate('/need-help');
      return;
    }

    openModal({
      children: (
        <div className={styles.modalOfHelpUkraine}>
          <p>{t('You have already applied!')}</p>
        </div>
      ),
      okSubmit: () => {},
    });
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.header__wrapper}>
            <Link to='/' className={styles.header__logo}>
              <img src={logo} alt="" width='45'/>
            </Link>
          </div>
          <div className={styles.header__wrapper}>
            <button className={styles.header__help} onClick={handleModal}>
              {t('I need help')}
            </button>
            <Lang/>
            <ThemeSwitcher />
            <button className={styles.header__burger} onClick={() => setIsShowDrawer(!isShowDrawer)}>
              <img src={menu} alt=""/>
            </button>
          </div>
        </div>
      </header>
      <Drawer isShow={isShowDrawer} onClose={setIsShowDrawer}/>
    </>
  )
}

export default Header;
