import {useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";

import UiButton from "../../components/ui/UiButton/UiButton";

import {getSliderSettings} from "../../utils/getSliderSettings";

import styles from './DemandPage.module.css'
import cn from "classnames";

const DemandPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {demand} = location.state;

  if (!demand) {
    navigate('/');
    return null;
  }

  const { photos = [] } = demand;

  const navigateNewThank = () => {
    navigate('/new-thank');
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1 className={cn('title', styles.title)}>{t('Title')}</h1>
        <p>{demand.title || 'Should be a title'}</p>
        <span className={styles.subTitle}>{t('Message')}</span>
        <p>{demand.message}</p>
      </div>
      {photos.length > 0 && <Slider {...getSliderSettings()} className='photoSlider'>
        {photos.map((photo, i) => {
          return <img key={`${i}_image`} src={`/get-photo?photo_id=${photo}`} alt="" style={{width: '100%'}}/>
        })}
      </Slider>}
      {demand.status !== 'init' ? <UiButton text={t('Give thanks')} onClick={navigateNewThank} />
        : <span className={styles.info}>{t('Your application is currently under review')}</span>}
    </div>
  )
}

export default DemandPage;
