import { useCallback, useEffect, useRef, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import CardList from "../../components/CardList/CardList";
import ShareOther from "../../components/ShareOther/ShareOther";

import {addMoreDonations} from "../../store/actions";

import styles from "./DonationsPage.module.css";

const DonationsPage = () => {
  const { demands = [], total } = useSelector((state) => state.donations);
  const [loading, setLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(!(demands.length % 10));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && isLoadMore) {
        dispatch(addMoreDonations(demands.length || 10, setLoading, setIsLoadMore))
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, isLoadMore]);


  useEffect(() => {
    const $slick = document.querySelector('.pageSlider')
    const $slickList = $slick.querySelector('.slick-list');

    const $slickActive = $slick.querySelector('.slick-active');
    const activeHeight = $slickActive.scrollHeight;

    $slickList.style.height = `${activeHeight}px`;
  }, [demands]);

  if (!demands.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.infoTitle}>{t('Needs for help')}<br/>({t('_applications_pending', { total })})</span>
      <div className={styles.targetHelps}>
        <CardList cards={demands} type='donations' lastElementRef={lastElementRef} />
      </div>
      <ShareOther showCryptoWallet={false} />
    </div>
  )
}

export default DonationsPage;
