import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import store from "./store/store";

import ModalProvider from "./context/Modal/ModalProvider";
import ThemeProvider from "./context/Theme/ThemeProvider";

import App from './containers/App/App';

import './i18n'

import './styles/style.css'
import {getCurrentTheme} from "./utils/currentTheme";

const theme = getCurrentTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <App/>
          </ModalProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
