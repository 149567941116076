import {useContext} from "react";
import { useDispatch } from "react-redux";
import {useTranslation} from "react-i18next";

import { fetchDonations, fetchThanks } from "../../store/actions";

import ModalContext from "../../context/Modal/ModalContext";

import langBlue from './img/lang-blue.svg'
import langOrange from './img/lang-orange.svg'

import styles from './Lang.module.css'

const lngs = {
  en: { nativeName: 'EN', modalName: 'English' },
  uk: { nativeName: 'UA', modalName: 'Українська' },
  ru: { nativeName: 'RU', modalName: 'Русский' },
  cs: { nativeName: 'CZ', modalName: 'Čeština' },
  de: { nativeName: 'DE', modalName: 'Deutsch' },
  es: { nativeName: 'ES', modalName: 'Español' },
  fr: { nativeName: 'FR', modalName: 'Francés' },
  lt: { nativeName: 'LT', modalName: 'Lietuvių ' },
  pl: { nativeName: 'PL', modalName: 'Polski' },
  pt: { nativeName: 'PT', modalName: 'Português' },
  zh: { nativeName: 'CN', modalName: '中文' },
}

const LangModal = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <img src={langOrange} alt="" width={24}/>
      <div className={styles.buttons}>
        {Object.keys(lngs).map((lng) =>
          <button
            key={lng}
            data-key={lng}
            onClick={() =>
              i18n.changeLanguage(lng)
                .then(() => {
                    dispatch(fetchDonations());
                    dispatch(fetchThanks());
                })
            }
            className={i18n.language === lng ? styles.active : ''}
          >
              {lngs[lng].modalName}
          </button>)}
      </div>
    </>
  )
}

const Lang = () => {
  const { i18n } = useTranslation();
  const {openModal} = useContext(ModalContext);

  const onClick = () => {
    openModal({
      children: <LangModal />,
      okSubmit: () => {},
      ok: null,
      isCloseAfter: true,
    });
  }

  return (
    <button className={styles.langButton} onClick={onClick}>
      <img src={langBlue} alt="" />
      <span>{lngs[i18n.resolvedLanguage].nativeName}</span>
    </button>
  )
}

export default Lang;
