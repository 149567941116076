import {useEffect, useState} from "react";
import {emailValidator, paymentCardValidator, phoneValidator} from "../utils/validations";
import {useTranslation} from "react-i18next";

export const useValidation = (value, validations) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const [isEmpty, setIsEmpty] = useState(true);
  const [minLength, setMinLength] = useState(false);
  const [maxLength, setMaxLength] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [paymentCard, setPaymentCard] = useState(false);

  const [inputValid, setInputValid] = useState(false)

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case 'isEmpty':
          setIsEmpty(!value);
          break;
        case 'minLength':
          setMinLength(value.length < validations[validation]);
          setErrorMessage(value.length < validations[validation] ? t('_min_length', { length: validations[validation] }) : '');
          break;
        case 'maxLength':
          setMaxLength(value.length > validations[validation]);

          break;
        case 'isEmail': {
          const isCheck = !emailValidator.test(String(value).toLowerCase());
          setEmail(isCheck);
          setErrorMessage(isCheck ? t('_wrong_email') : '');
          break;
        }
        case 'isPhone': {
          const isCheck = !phoneValidator.test(String(value).toLowerCase());
          setPhone(isCheck);
          setErrorMessage(isCheck ? t('_wrong_phone') : '');
          break;
        }
        case 'isPaymentCard': {
          const isCheck = !paymentCardValidator.test(String(value).toLowerCase());
          setPaymentCard(isCheck);
          setErrorMessage(isCheck ? t('_wrong_format_card_number') : '');
          break;
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (isEmpty || minLength || maxLength || email) {
      setInputValid(true)
    }
  }, [isEmpty, minLength, maxLength, email]);

  return {
    isEmpty,
    minLength,
    maxLength,
    email,
    phone,
    paymentCard,
    inputValid,
    errorMessage
  }
}
