import cn from 'classnames';

import {noop} from "../../../utils/noop";

import styles from './UiInput.module.css'

const UiInput = ({ type = 'text', placeholder, id, onChange, onBlur = noop, value, labelText, required = false, isError }) => {
  const requiredStar = required && (<span style={{color: 'red'}}>*</span>)
  return (
    <label htmlFor={id} className={cn(styles.label, isError ? styles.error : '')}>
      {labelText && <span>{labelText} {requiredStar}</span>}
      <input type={type} placeholder={placeholder} id={id} onChange={onChange} value={value} onBlur={onBlur}/>
    </label>
  )
}

export default UiInput;
