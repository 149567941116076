import {combineReducers} from "redux";
import donationsReducer from "./donationsReducer";
import thanksReducer from "./thanskReducer";
import userReducer from "./userReducer";

export default combineReducers({
  donations: donationsReducer,
  thanks: thanksReducer,
  user: userReducer,
});
