import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useTranslation} from "react-i18next";

import CardList from "../../components/CardList/CardList";

import styles from './ThanksPage.module.css'
import { addMoreThanks } from "../../store/actions";


const ThanksPage = () => {
  const { t } = useTranslation();
  const { thanks = [] } = useSelector(({ thanks }) => thanks);
  const [loading, setLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(!(thanks.length % 10));

  const dispatch = useDispatch();

  const observer = useRef();

  const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && isLoadMore) {
        dispatch(addMoreThanks(thanks.length || 10, setLoading, setIsLoadMore))
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, isLoadMore]);

  useEffect(() => {
    const $slick = document.querySelector('.pageSlider')
    const $slickList = $slick.querySelector('.slick-list');

    const $slickActive = $slick.querySelector('.slick-active');
    const activeHeight = $slickActive.scrollHeight;

    $slickList.style.height = `${activeHeight}px`;
  }, [thanks]);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.infoTitle}>{t('Reviews')}</h2>

      <div className={styles.helpsBlock}>
        {thanks && thanks.length ?
          <CardList cards={thanks} type='thanks' lastElementRef={lastElementRef}/> :
          <p className={styles.text}>{t('_not_thank_items')}</p>
        }
      </div>
    </div>
  )
}

export default ThanksPage;
