export const getUrlParam = (url, name) => {
  const [_, paramsStr] = url.split('?');
  if (!paramsStr) {
    return null;
  }
  const params = paramsStr.split('&').reduce((acc, p) => {
    const [key, value] = p.split('=');
    acc[key] = value;
    return acc
  }, {});

  return params[name];
}
