import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import {setLocalStorage} from "../../utils/localStorage";
import {USED_DATA_LS} from "../../constants/api";
import {
  ADD_DONATED,
  ADD_INFO_USER,
  ADD_MORE_DONATIONS,
  ADD_MORE_THANKS,
  FETCH_DONATIONS,
  FETCH_THANKS,
  FETCH_USER
} from "../types";
import { noop } from "../../utils/noop";
import { LOAD_LIMIT_TEN } from "../../constants/other";

export const fetchUser = (devid, reflink = null) => async (dispatch) => {
  if (!devid) {
    devid = uuidv4();
  }

  const data = { devid };
  if (reflink) {
    data.reflink = reflink
  }

  const response = await axios.post('/api/auth', data);

  const { id, sid, account } = await response.data;
  setLocalStorage(USED_DATA_LS, { devid });
  dispatch({ type: FETCH_USER, payload: { id, sid, ...account, isShowProfile: false } });
}

export const fetchDonations = () => async (dispatch) => {
  const { data } = await axios.get(`/api/list-demands?limit=${LOAD_LIMIT_TEN}`);
  dispatch({ type: FETCH_DONATIONS, payload: { demands: data.demands.reverse(), total: data.total } });
}

export const addMoreDonations = (offset, setLoading = noop, isLoadMore = noop) => async (dispatch) => {
  setLoading(true);
  const { data } = await axios.get(`/api/list-demands?limit=${LOAD_LIMIT_TEN}&offset=${offset}`);
  dispatch({ type: ADD_MORE_DONATIONS, payload: { demands: data.demands.reverse() }});
  isLoadMore(data.demands.length !== 0 && !(data.demands.length % LOAD_LIMIT_TEN))
  setLoading(false);
}

export const addInfoUser = (user) => ({
  type: ADD_INFO_USER,
  payload: user,
});

export const fetchThanks = () => async (dispatch) => {
  const { data } = await axios.get(`/api/list-thanks?limit=${LOAD_LIMIT_TEN}`);
  dispatch({ type: FETCH_THANKS, payload: { thanks: data.thanks }})
}

export const addMoreThanks = (offset, setLoading = noop, isLoadMore = noop) => async (dispatch) => {
  setLoading(true);
  const { data } = await axios.get(`/api/list-thanks?limit=${LOAD_LIMIT_TEN}&offset=${offset}`);
  dispatch({ type: ADD_MORE_THANKS, payload: { thanks: data.thanks }})
  isLoadMore(data.thanks.length !== 0 && !(data.thanks.length % LOAD_LIMIT_TEN))
  setLoading(false);
}

export const addUserDonated = (amount) => ({
  type: ADD_DONATED,
  payload: amount
});
