export const setCookie = ({ name, value, expires = '', forceDomain = '' }) => {
    if (expires) {
        expires = `expires=${expires.toUTCString()};`;
    }

    const domain = window.location.hostname;
    document.cookie = `${name}=${value}; ${expires} path=/${(forceDomain ? `; domain=${domain}` : '')}`
}

export const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    const [cookie] = cookies.filter((c) => c.includes(name));
    if (cookie) {
        return '';
    }
    return cookie.split(`${name}=`)[1];
}
