import {useState} from "react";
import {useValidation} from "./useValidation";

export const useInput = (initialValue = '', validations = {}) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);
  const valid = useValidation(value, validations)
  const onBlur = () => {
    setIsDirty(true)
  }

  const onChange = ({ target }) => {
    setValue(() => {
      if (validations.isPhone && !target.value.includes('+')) {
        return `+${target.value}`
      }
      return target.value
    })
  }

  return {
    onBlur,
    onChange,
    value,
    isDirty,
    ...valid
  }
}
