import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";

import { setCookie } from "./utils/cookies";
import { LANG_COOKIE } from "./constants/other";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    whitelist: ['en', 'uk', 'ru', 'cs', 'de', 'es', 'fr', 'lt', 'pl', 'pt', 'zh' ],
    wait: true
  });

i18next.on('languageChanged', function(lng) {
    setCookie({ name: LANG_COOKIE, value: lng })
});
