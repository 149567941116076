import styles from './Footer.module.css'
import NavSliderBtn from "../NavSliderBtn/NavSliderBtn";

const Footer = ({ onNext, onPrev }) => {
  return (
    <footer className={styles.footer}>
      {/*<NavSliderBtn position='left' onClick={onPrev} />*/}
      <a href="https://uagromada.org">uagromada.org</a>
      {/*<NavSliderBtn position='right' onClick={onNext} />*/}
    </footer>
  )
}

export default Footer;
