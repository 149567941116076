import {useContext, useState} from "react";

import ThemeContext from "../../context/Theme/ThemeContext";
import {THEME_DARK, THEME_LIGHT} from "../../constants/theme";

import styles from './ThemeSwitcher.module.css';

const ThemeSwitcher = () => {
  const {theme, changeTheme} = useContext(ThemeContext);
  const [themeSwitcher, setThemeSwitcher] = useState(theme);

  const onChange = () => {
    setThemeSwitcher((prevState) => {
      changeTheme(prevState === THEME_LIGHT ? THEME_DARK : THEME_LIGHT)
      return prevState === THEME_LIGHT ? THEME_DARK : THEME_LIGHT
    });
  }

  return (
    <label className={styles.switch}>
      <input type="checkbox" className={styles.checkbox} onChange={onChange} checked={themeSwitcher === THEME_DARK}/>
      <span className="slider round" />
    </label>
  )
}

export default ThemeSwitcher;
