import ThemeContext from "./ThemeContext";

import {changeCssVariables} from "../../utils/changeCssVariables";
import {setCurrentTheme} from "../../utils/currentTheme";
import {useEffect} from "react";

const ThemeProvider = ({ children, theme }) => {
  const changeTheme = (theme) => {
    changeCssVariables(theme);
    setCurrentTheme(theme)
  }

  useEffect(() => {
    changeCssVariables(theme);
  }, [])

  return (
    <ThemeContext.Provider value={{
      changeTheme,
      theme
    }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider;
