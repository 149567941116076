import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";

import UiButton from "../../components/ui/UiButton/UiButton";
import ShareOther from "../../components/ShareOther/ShareOther";
import UserBlock from "../../components/UserBlock/UserBlock";
import Tagline from "../../components/Tagline/Tagline";

import {addInfoUser} from "../../store/actions";

import {toastMessage} from "../../utils/toastMessage";

import smallArrow from "./img/smallArrow.svg";

import styles from './Profile.module.css';

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {firstname, surname, email, phone, received, donated, attracted, id, sid, avatar_id, } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addInfoUser({
      isShowProfile: true
    }));
  }, []);

  const [demand, setDemand] = useState(null);
  useEffect(() => {
    (async () => {
      if (received !== undefined) {
        try {
          const results = await axios.post('/api/get-my-demand', {id, sid});
          setDemand(results.data)
        } catch (e) {
          const errorMessage = e.response?.data?.message || e.response?.data?.error || e.message
          toastMessage('error', errorMessage);
        }
      }
    })();
  }, [received]);

  const handleNavigateDemand = () => {
    navigate('/my-demand', {
      state: {
        demand,
      }
    })
  }

  const navigateNewThank = (e) => {
    e.stopPropagation();
    navigate('/new-thank')
  }

  return (
    <div className={styles.wrapper}>
      <Tagline id={id} sid={sid} isSave={true} />
      <UserBlock user={{ firstname, surname, email, phone, id, sid, avatar_id }} />

      <div className={styles.contributions}>
        <div className={styles.contribWrapper}>
          <span>{t('My contribution')}:</span>
          <span>{donated || 0} €</span>
        </div>
        <div className={styles.contribWrapper}>
          <span>{t('Helped me thanks to')}:</span>
          <span>{attracted || 0} €</span>
        </div>
        <UiButton text={t(donated ? 'wantHelp' : '_help_more')} onClick={() => navigate('/your-help')}/>
      </div>

      {demand &&
        <div className={styles.myRemand}>
          <h2>{t('I need')}</h2>
          <div className={styles.demand} onClick={handleNavigateDemand}>
            <div className={styles.demandWrapper}>
              <span>{demand.title || demand.message}</span>
              <img src={smallArrow} alt=""/>
            </div>
            {demand.status !== 'init' ? <button className={styles.demandButton} onClick={navigateNewThank}>{t('Give thanks')}</button>
              : <span className={styles.demandInfo}>{t('Your application is currently under review')}</span>}
          </div>
        </div>
      }
      <ShareOther />
    </div>
  )
}

export default ProfilePage;
