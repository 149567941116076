import {forwardRef, useContext, useRef, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import axios from "axios";
import {Tooltip as ReactTooltip} from 'react-tooltip'

import ModalContext from "../../context/Modal/ModalContext";

import UiInput from "../ui/UiInput/UiInput";

import {toastMessage} from "../../utils/toastMessage";

import editIcon from './img/edit_note.svg';

import "react-tooltip/dist/react-tooltip.css";
import styles from './Tagline.module.css';

const TaglineModal = forwardRef(({setTagline}, ref) => {
    const {t} = useTranslation();
    return (
      <div>
          <h3 style={{ marginBottom: '10px' }}>{t('_suggest_slogan')}</h3>
          <UiInput
            type='text'
            id='tagline'
            placeholder={t('_enter_slogan')}
            value={ref.current}
            onChange={(e) => setTagline(() => {
                ref.current = e.target.value
                return ref.current;
            })}
          />
      </div>
    )
})

const Tagline = ({id, sid, isSave = false}) => {
    const [tagline, setTagline] = useState('');
    const taglineRef = useRef();
    const {openModal} = useContext(ModalContext);
    const {t} = useTranslation();

    const saveTagline = async () => {
        try {
            await axios.post('/api/new-slogan', {id, sid, body: taglineRef.current});
            toastMessage('success', t('_success_slogan'));
            taglineRef.current = undefined;
        } catch (e) {
            const errorMessage = e.response?.data?.message || e.response?.data?.error || e.message;
            console.log('Error', errorMessage)
            toastMessage('error', t(errorMessage));
        }
    }

    const onClick = () => {
        openModal({
            children: <TaglineModal ref={taglineRef} tagline={tagline} setTagline={setTagline}/>,
            cancel: t('Cancel'),
            ok: t('Save'),
            okSubmit: saveTagline,
        })
    }

    return (
      <div className={styles.tagline}>
          <h1>
              <Trans i18nKey={'homeTitle'} components={{br: <br/>}}/>
          </h1>
          {isSave &&
            <>
                <button onClick={onClick} id='tagline-tooltip'>
                    <img src={editIcon} alt=""/>
                </button>
                <ReactTooltip
                  anchorId="tagline-tooltip"
                  place="bottom"
                  variant="dark"
                  content={t('_suggest_slogan')}
                />
            </>}

      </div>

    )
}

export default Tagline;
