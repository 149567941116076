export const sendForm = (data = {}, url = '', attr = {}) => {
  const defaultAttr = {method: 'post', enctype: 'utf-8', style: 'display: none', name: 'payment'};
  const attributes = {...defaultAttr, ...attr, action: url};
  const form = document.createElement('form');
  Object.entries(attributes).forEach(([name, value]) => form.setAttribute(name, value));

  const inputs = Object.entries(data).map(([name, value]) => `<input type="hidden" name="${name}" value="${value}">`);
  form.innerHTML = inputs.join('');

  document.querySelector('body').append(form);
  form.submit();
};
