import checkboxIcon from './img/checkbox.svg'
import checkedIcon from './img/checked.svg'

import styles from './UiCheckbox.module.css'

const UiCheckbox = ({ text, checked, onChange, id }) => {
  return (
    <label className={styles.checkbox} htmlFor={id}>
      <input type="checkbox" onChange={onChange} checked={checked} id={id} />
      <img src={checked ? checkedIcon : checkboxIcon} alt="" />
      <span>{text}</span>
    </label>
  )
}

export default UiCheckbox;
