import {THEME_DARK, THEME_LIGHT} from "../constants/theme";

const THEME = 'theme';

export const setCurrentTheme = (theme) => {
  localStorage.setItem(THEME, theme)
}

export const getCurrentTheme = () => {
  const currentTheme = localStorage.getItem(THEME);
  if (currentTheme) {
    return currentTheme;
  }

  return (window.matchMedia("(prefers-color-scheme: dark)").matches && THEME_DARK) || THEME_LIGHT;
}
