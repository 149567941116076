import cn from 'classnames';

import arrowBlack from './img/arrowBlack.svg'
import arrowWhite from './img/arrowWhite.svg'

import styles from './UiButton.module.css'

const UiButton = ({ text, color = 'primary', isArrow, disabled, onClick, style }) => {
  return (
    <button className={cn(styles.btn, styles[`btn--${color}`])} onClick={onClick} disabled={disabled} style={style}>
      <span>{text}</span>
      {isArrow && <img src={arrowBlack} alt=""  /> }
    </button>
  )
}

export default UiButton;
