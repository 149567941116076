import {useContext, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import axios from "axios";
import AvatarEditor from 'react-avatar-editor'

import {ADD_INFO_USER} from "../../store/types";
import {USED_DATA_TMP_LS} from "../../constants/api";

import UiInput from "../ui/UiInput/UiInput";

import ModalContext from "../../context/Modal/ModalContext";

import {getLocalStorage, setLocalStorage} from "../../utils/localStorage";
import {toastMessage} from "../../utils/toastMessage";

import userIcon from "./img/user.svg";
import notVerifyIcon from "./img/notVerify.svg";
import editIcon from "./img/edit.svg";
import uploadIcon from "./img/file_upload.svg";

import styles from './UserBlock.module.css';

const UpdateUserModal = (props) => {
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState(props.firstname || '');
  const [surname, setSurname] = useState(props.surname || '');
  const [phone, setPhone] = useState(props.phone || '');
  const [email, setEmail] = useState(props.email || '');

  useEffect(() => {
    setLocalStorage(USED_DATA_TMP_LS, { firstname, surname, phone, email })
  }, [firstname, surname, phone, email]);

  return (
    <div>
      <h2 className={styles.h2}>{t('Update profile')}*</h2>
      <UiInput
        labelText={t('Your firstname')}
        placeholder={t('Enter firstname')}
        id='firstname'
        value={firstname}
        onChange={(e) => setFirstname(e.target.value)}
      />
      <UiInput
        labelText={t('Your lastname')}
        placeholder={t('Enter lastname')}
        id='lastname'
        value={surname}
        onChange={(e) => setSurname(e.target.value)}
      />
      <UiInput
        labelText={t('Email')}
        type='email'
        id='email'
        placeholder={t('Enter your email')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <UiInput
        labelText={t('Contact number')}
        type='tel'
        id='phone'
        placeholder={t('Enter your phone')}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />

      <span className={styles.span}>*{t('All are optional')}</span>
    </div>
  )
}

const AvatarUserModal = ({ editorRef, avatar }) => {
  const { t } = useTranslation();
  const [photo, setPhoto] = useState({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: avatar
  });

  // useEffect(() => {
  //   setLocalStorage(USED_AVATAR_TMP_LS, photo)
  // }, [photo]);

  const handleRangePhoto = ({ target }) => {
    setPhoto({
      ...photo,
      zoom: +target.value
    });
  }

  return (
    <div className={styles.modalAvatarWrapper}>
      {!photo.cropperOpen
        ? <img src={photo.croppedImg} alt="" className={styles.modalAvatar} /> :
        <>
          <AvatarEditor
            ref={editorRef}
            image={photo.img}
            width={230}
            height={230}
            border={10}
            borderRadius={10}
            scale={photo.zoom}
          />
          <input
            className={styles.modalRange}
            type="range"
            value={photo.zoom}
            min={1}
            max={10}
            step={0.1}
            onChange={handleRangePhoto}/>
        </>}
      <label htmlFor='avatar' className={styles.modalFileLabel}>
        <input type="file" id='avatar' accept="image/*" onChange={({target}) => {
          const url = URL.createObjectURL(target.files[0]);
          setPhoto({
            ...photo,
            img: url,
            cropperOpen: true
          })
        }} />
        <img src={uploadIcon} alt="" width='30'/>
        <span>{t('_upload_avatar')}</span>
      </label>
    </div>
  )
}

const UserBlock = ({ user }) => {
  const editorRef = useRef();
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const dispatch = useDispatch()

  const updateUser = () => {
    const dataRaw = getLocalStorage(USED_DATA_TMP_LS);

     const data = Object.keys(dataRaw).reduce((acc, key) => {
        if (dataRaw[key].length) {
          acc[key] = dataRaw[key].trim();
        }

        return acc;
      }, { sid: user.sid, id: user.id });

    axios.post('/api/update-profile', data).then((results) => {
      dispatch({
        type: ADD_INFO_USER,
        payload: dataRaw
      })
    }).catch((e) => {
      const errorMessage = e.response?.data?.message || e.response?.data?.error || e.message;
      console.log('Error', errorMessage)
      toastMessage('error', t(errorMessage));
    })
  }

  const onEditProfile = () => {
    openModal({
      children: <UpdateUserModal {...user} />,
      cancel: t('Cancel'),
      ok: t('Save'),
      okSubmit: updateUser,

    })
  }

  const onUploadPhoto = () => {
    openModal({
      children: <AvatarUserModal editorRef={editorRef} avatar={user.avatar_id ? `/get-avatar?account_id=${user.id}&avatar_id=${user.avatar_id}` : userIcon}/>,
      okSubmit: async () => {
        const canvasScaled = editorRef.current.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();

        const formData = new FormData();
        formData.append('avatar', croppedImg);
        formData.append('id', user.id);
        formData.append('sid', user.sid);

        try {
          const response = await axios.post('/api/create-avatar', formData);
          dispatch({
            type: ADD_INFO_USER,
            payload: { avatar_id: response.data.avatar_id }
          })
        } catch (e) {
          const errorMessage = e.response?.data?.message || e.response?.data?.error || e.message;
          console.log('Error', errorMessage)
          toastMessage('error', t(errorMessage));
        }
      },
      cancel: t('Cancel'),
      ok: t('Save'),
    })
  }

  return (
    <div className={styles.wrapper}>
      <img src={user.avatar_id ? `/get-avatar?account_id=${user.id}&avatar_id=${user.avatar_id}` : userIcon} alt="" onClick={onUploadPhoto} className={styles.avatar} />
      <div>
        <span className={styles.name}>{user.firstname ? `${user.firstname} ${user.surname}` : t('Anonymous donor')}</span>
        <span className={styles.verify}><img src={notVerifyIcon} alt=""/>0 {t('awards', {count: 0})}</span>
      </div>
      <button className={styles.edit} onClick={onEditProfile}>
        <img src={editIcon} alt="" />
      </button>
    </div>
  )
}

export default UserBlock;
