import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from 'classnames';

import { formattingUrl } from "../../utils/formattingUrl";

import arrowIcon from './img/arrow.svg';

import styles from './CardList.module.css';

const Card = ({ account_id, title, message, title_orig, message_orig, link, type, demand = {}, photo_id, isLastElement, lastElementRef }) => {
  const { t } = useTranslation();
  const navigator = useNavigate()
  const [open, setOpen] = useState(false);
  const [openDemandTranslate, setOpenDemandTranslate] = useState(false);

  const onNavigate = (id) => (e) => {
    e.stopPropagation();
    navigator(`/donations/${id}`);
  }

  const onCloseWrapper = (e) => {
    if (open) {
      e.stopPropagation();
    }
  }

  const msg = type === 'thanks' ? (message ? message : t('_request_completed')) : message;

  return (
    <div className={styles.card} ref={isLastElement ? lastElementRef : undefined}>
      <div className={styles.cardWrapper}>
        <div className={cn(styles.cardTranslate, open && styles.open)}>
          {title && <h3 className={styles.title}>{title}</h3>}
          <p className={styles.text}>{msg}</p>
        </div>
        {(title_orig || message_orig) &&
          <>
            <div className={cn(styles.cardOrigin, open && styles.openOrigin)}>
              {title_orig && <h3 className={styles.title}>{title_orig}</h3>}
              <p className={styles.text}>{message_orig}</p>
            </div>
            <button
              className={styles.btnTranslate}
              onClick={() => setOpen((prevState) => !prevState)}
            >
              {t(open ? '_hide_origin' : '_show_origin')}
            </button>
          </>
        }
      </div>
      {demand.message &&
        <div className={styles.cardDemand}>
          <img src={arrowIcon} alt=""/>
          <div className={styles.cardDemandWrapper}>
            <div className={cn(styles.cardTranslate, openDemandTranslate && styles.open)}>
              {demand.title && <h3 className={styles.title}>{demand.title}</h3>}
              <p className={styles.text}>{demand.message}</p>
            </div>
            {(demand.title_orig || demand.message_orig) && (
              <>
                <div className={cn(styles.cardOrigin, openDemandTranslate && styles.openOrigin)}>
                  {demand.title_orig && <h3 className={styles.title}>{demand.title_orig}</h3>}
                  {demand.message_orig && <p className={styles.text}>{demand.message_orig}</p>}
                </div>
                <button
                  className={styles.btnTranslate}
                  onClick={() => setOpenDemandTranslate((prevState) => !prevState)}
                >
                  {t(openDemandTranslate ? '_hide_origin' : '_show_origin')}
                </button>
              </>
            )}
          </div>
        </div>
      }
      {photo_id && <img className={styles.cardPhoto} src={`/get-photo?photo_id=${photo_id}`} alt=""/>}
      {link && <a href={formattingUrl(link)} className={styles.cardLink} rel="noreferrer" target="_blank">{link}</a>}
      <button className={styles.cardButton} onClick={onNavigate(account_id)}>{t('wantHelp')}</button>
    </div>
  )
}

export default Card;
