import {useTranslation} from "react-i18next";
import {toastMessage} from "../../utils/toastMessage";
import {copyTextToClipboard} from "../../utils/copyTextToClipboard";

import copyIcon from './img/copy-link.svg'

import styles from './ClipboardCopy.module.css'

const ClipboardCopy = ({ text }) => {
  const { t } = useTranslation()

  const handleCopyClick = () => {
    copyTextToClipboard(text)
      .then(() => {
        toastMessage('info', t('Copied!'));
      })
      .catch(e => {
      })
  }

  return (
    <div className={styles.copy} onClick={handleCopyClick}>
      <img src={copyIcon} alt=""/>
      <span>{t('Copy link')}</span>
    </div>
  )
}

export default ClipboardCopy;
