import HomePage from "../containers/HomePage/HomePage";
import INeedHelp from "../containers/INeedHelpPage/INeedHelp";
import YourHelpPage from "../containers/YourHelpPage/YourHelpPage";
import DonationsPage from "../containers/DonationsPage/DonationsPage";
import TargetHelpPage from "../containers/TargetHelpPage/TargetHelpPage";
import ThanksPage from "../containers/ThanksPage/ThanksPage";
import ProfilePage from "../containers/ProfilePage/ProfilePage";
// import PaymentPage from "../containers/PaymentPage/PaymentPage";
import DemandPage from "../containers/DemandPage/DemandPage";
import NewThankPage from "../containers/NewThankPage/NewThankPage";
import PayReturnPage from "../containers/PayReturnPage/PayReturnPage";
import ThankPage from "../containers/ThankPage/ThankPage";

const routesConfig = [
  // {
  //   path: '/',
  //   exact: true,
  //   element: <HomePage/>,
  // },
  {
    path: '/need-help',
    element: <INeedHelp/>,
  },
  {
    path: '/my-demand',
    element: <DemandPage/>
  },
  {
    path: '/your-help',
    element: <YourHelpPage/>,
  },
  // {
  //   path: '/donations',
  //   element: <DonationsPage/>,
  // },
  {
    path: '/donations/:helpId',
    element: <TargetHelpPage/>,
  },
  // {
  //   path: '/thanks',
  //   element: <ThanksPage/>,
  // },
  {
    path: '/thanks/:id',
    element: <ThankPage/>,
  },
  {
    path: '/new-thank',
    element: <NewThankPage/>,
  },
  {
    path: '/profile',
    element: <ProfilePage/>
  },
  // {
  //   path: '/payment',
  //   element: <PaymentPage/>
  // },
  {
    path: '/pay-success',
    element: <PayReturnPage status='success' />
  },
  {
    path: '/pay-fail',
    element: <PayReturnPage status='error' />
  }
]

export default routesConfig;
