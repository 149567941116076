import {useLocation} from "react-router-dom";
import cn from 'classnames';

import arrow from './img/arrowBlack.svg'

import styles from './NavSliderBtn.module.css'

const pathNames = ['/', '/donations', '/thanks'];

const NavSliderBtn = ({ position, onClick }) => {
  const location = useLocation();

  if (!pathNames.includes(location.pathname)) {
    return null
  }

  return (
    <button className={cn(styles.button, styles[position])} onClick={onClick}>
      <img src={arrow} alt=""/>
    </button>
  )
}

export default NavSliderBtn;
