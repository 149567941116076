import cn from 'classnames';

import styles from './UiSwitch.module.css'

const UiSwitch = ({ checked = false, onChange, id, text, textChecked }) => {
  return (
    <label htmlFor={id} className={styles.label}>
      <span className={styles.text}>{text}</span>
      <div className={styles.switch}>
        <input type="checkbox" checked={checked} className={styles.input} onChange={onChange} id={id} />
        <span className={cn(styles.slider, styles.round)} />
      </div>
      <span className={styles.text}>{textChecked}</span>
    </label>
  )
}

export default UiSwitch;
