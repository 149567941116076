import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cn from "classnames";

import UiButton from "../../components/ui/UiButton/UiButton";

import styles from './ThankPage.module.css';

const ThankPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {thank} = location.state;
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.infoTitle}>{t('Thanks from', { author: thank.author })}</h1>
      <p>{thank.message}</p>
      <a href={thank.link} >{t('_link_social')}</a>
      <UiButton text={t('_help_more')} onClick={() => navigate(`/donations/${thank.account_id}`)} />
    </div>
  )
}

export default ThankPage;
