import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Slider from "react-slick";
import {Trans, useTranslation} from "react-i18next";
import cn from "classnames";
import axios from "axios";

import UiButton from "../../components/ui/UiButton/UiButton";
import UiInput from "../../components/ui/UiInput/UiInput";
import UiTextarea from "../../components/ui/UiTextarea/UiTextarea";
import UiSelect from "../../components/ui/UiSelect/UiSelect";

import {addInfoUser} from "../../store/actions";

import ModalContext from "../../context/Modal/ModalContext";

import {getSliderSettings} from "../../utils/getSliderSettings";
import {toastMessage} from "../../utils/toastMessage";

import {useInput} from "../../hooks/useInput";

import imgUpload from './img/file_upload.svg'

import styles from './INeedHelp.module.css'
import UiCheckbox from "../../components/ui/UiCheckbox/UiCheckbox";

const demandTypes = ['direct', 'volunteer', 'organization'];

const INeedHelp = () => {
  const {t} = useTranslation()
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {openModal} = useContext(ModalContext);

  const [demandType, setDemandType] = useState(demandTypes[0]);
  const firstname = useInput('', {isEmpty: true, minLength: 4});
  const surname = useInput('', {isEmpty: true, minLength: 4});
  const phone = useInput('+', {isEmpty: true, isPhone: true});
  const email = useInput('', {isEmpty: true, isEmail: true});
  const title = useInput('', {isEmpty: true, minLength: 10, maxLength: 40});
  const message = useInput('', {isEmpty: true, minLength: 10});
  const organization = useInput('', {isEmpty: true, minLength: 10});
  const cardNum = useInput('', {isEmpty: false, isPaymentCard: true});
  const [photos, setPhotos] = useState([]);
  const [publicPhoto, setPublicPhoto] = useState({})

  const [isDisable, setIsDisable] = useState(false);

  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (!(firstname.value.length && surname.value.length && phone.value.length && email.value.length && message.value.length && title.value.length)) {
      toastMessage('warn', t('Fill in all the fields!'));
      return;
    }

    const cardNumber = cardNum.value.replaceAll(' ', '');

    if (cardNumber.length && (cardNumber.length < 13 || cardNumber.length > 16 || isNaN(cardNumber))) {
      toastMessage('warn', t('_wrong_format_card_number'));
      return;
    }

    if (!photos.length) {
      toastMessage('warn', t('Please, upload at least 1 photo!'));
      return;
    }

    setIsDisable(true);

    const data = {
      id: user.id,
      sid: user.sid,
      firstname: firstname.value.trim(),
      surname: surname.value.trim(),
      phone: phone.value.trim(),
      email: email.value.trim(),
      message: message.value.trim(),
      title: title.value.trim(),
      type: demandType,
    }

    if (cardNumber) {
      data.cardnum = cardNumber
    }

    if (demandType === 'organization') {
      data.organization = organization.value.trim()
    }

    try {
      await axios.post('/api/new-demand', data);

      dispatch(addInfoUser({
        firstname: data.firstname,
        surname: data.surname,
        received: '0',
        email: data.email,
        phone: data.phone
      }));

      const formsData = [];
      for (const photo of photos) {
        const formData = new FormData();
        formData.append('photo', photo);
        formData.append('id', user.id);
        formData.append('sid', user.sid);
        formData.append('public', publicPhoto[photo.name] ? '1' : '0')
        formsData.push(formData);
      }
      await Promise.all(formsData.map((formData) => axios.post('/api/add-demand-photo', formData)));
      setSuccess(true);
    } catch (e) {
      console.log(e)
      const errorMessage = e.response?.data?.message || e.response?.data?.error || e.response?.data?.reason || e.message;
      toastMessage('error', t(errorMessage));
    } finally {
      setIsDisable(false);
    }
  }

  const showModal = () => openModal({
    children: (
      <div className={styles.modalOfHelpUkraine}>
        <Trans i18nKey='_modal_create_new_help_ukraine' components={{p: <p/>, ul: <ul/>, li: <li/>, br: <br/>}}>
          <p>Currently, we are engaged in the organization of assistance only for Ukrainians. <br/>You can only apply if
            you:</p>
          <ul>
            <li>Ukrainian</li>
            <li>A volunteer who helps Ukrainians</li>
            <li>A representative of an organization that helps Ukrainians</li>
          </ul>
        </Trans>
      </div>
    ),
    cancel: t('No'),
    cancelSubmit: () => {
      navigate('/');
    },
    okSubmit: () => {},
    externalClose: false,
  })

  useEffect(()=> {
    showModal();
  }, []);

  // if (user.received && !success) {
  //   navigate('/');
  //   return null;
  // }
  //direct volunteer organization

  return (
    <div className={styles.wrapper}>
      {success ?
        <>
          <h2 className={styles.title}>{t('Request placed')}</h2>
          <span className={styles.subTitle}>{t('Thank you. Together we will definitely make it!')}</span>
          <UiButton text={t('To my requests')} onClick={() => navigate('/profile')}
                    style={{width: '100%', marginTop: '40px'}}/>
        </>
        :
        <>
          <h1 className={cn('title', styles.title)}>{t('I need help')}</h1>
          <div className={styles.formWrapper}>
            <UiSelect
              onChange={(event) => {
                setDemandType(event.target.value)
              }}
              options={demandTypes}
              optionsTranslate={demandTypes.map((dt) => t(`_${dt}`))}
              labelText={t('_choose_option')}
              required={true}
              currentValue={demandType}
            />

            {demandType === 'organization' && <UiInput
              labelText={t('_name_organization')}
              id='type'
              placeholder={t('_enter_name_organization')}
              value={organization.value}
              onChange={(e) => organization.onChange(e)}
              onBlur={(e) => organization.onBlur(e)}
              required={true}
              isError={organization.isDirty && organization.errorMessage}
            />}

            <UiInput
              labelText={t('Your firstname')}
              id='firstname'
              placeholder={t('Enter firstname')}
              value={firstname.value}
              onChange={(e) => firstname.onChange(e)}
              onBlur={(e) => firstname.onBlur(e)}
              required={true}
              isError={firstname.isDirty && firstname.errorMessage}
            />
            {(firstname.isDirty && firstname.errorMessage) && <span
              className={styles.errorMessage}>{firstname.isEmpty ? t('_fill_field') : firstname.errorMessage}</span>}
            <UiInput
              labelText={t('Your lastname')}
              id='lastname'
              placeholder={t('Enter lastname')}
              value={surname.value}
              onChange={(e) => surname.onChange(e)}
              onBlur={(e) => surname.onBlur(e)}
              required={true}
              isError={surname.isDirty && surname.errorMessage}
            />
            {(surname.isDirty && surname.errorMessage) &&
              <span
                className={styles.errorMessage}>{surname.isEmpty ? t('_fill_field') : surname.errorMessage}</span>}
            <UiInput
              labelText={t('Email')}
              type='email'
              id='email'
              placeholder={t('Enter your email')}
              value={email.value}
              onChange={(e) => email.onChange(e)}
              onBlur={(e) => email.onBlur(e)}
              required={true}
              isError={email.isDirty && email.errorMessage}
            />
            {(email.isDirty && email.errorMessage) &&
              <span className={styles.errorMessage}>{email.isEmpty ? t('_fill_field') : email.errorMessage}</span>}
            <UiInput
              labelText={t('Contact number')}
              type='tel'
              id='phone'
              placeholder={t('Enter your phone')}
              value={phone.value}
              onChange={(e) => phone.onChange(e)}
              onBlur={(e) => phone.onBlur(e)}
              required={true}
              isError={phone.isDirty && phone.errorMessage}
            />
            {(phone.isDirty && phone.errorMessage) &&
              <span className={styles.errorMessage}>{phone.isEmpty ? t('_fill_field') : phone.errorMessage}</span>}
            <UiInput
              labelText={t('_card_number_label')}
              type='number'
              id='cardNum'
              placeholder={t('_card_number_placeholder')}
              value={cardNum.value}
              onChange={(e) => cardNum.onChange(e)}
              onBlur={(e) => cardNum.onBlur(e)}
              required={false}
              isError={!cardNum.isEmpty && cardNum.isDirty && cardNum.errorMessage}
            />
            {(!cardNum.isEmpty && cardNum.isDirty && cardNum.errorMessage) &&
              <span className={styles.errorMessage}>{cardNum.errorMessage}</span>}
            <UiInput
              labelText={t('What help is needed?')}
              id='title'
              placeholder={t('Enter what help is needed')}
              value={title.value}
              onChange={(e) => title.onChange(e)}
              onBlur={(e) => title.onBlur(e)}
              required={true}
              isError={title.isDirty && title.errorMessage}
            />
            {(title.isDirty && title.errorMessage) &&
              <span className={styles.errorMessage}>{title.isEmpty ? t('_fill_field') : title.errorMessage}</span>}

            <UiTextarea
              id='message'
              labelText={t('What do you need?')}
              placeholder={t('Describe the request or problem')}
              value={message.value}
              onChange={(e) => message.onChange(e)}
              onBlur={(e) => message.onBlur(e)}
              required={true}
              isError={message.isDirty && message.errorMessage}
            />
            {(message.isDirty && message.errorMessage) &&
              <span
                className={styles.errorMessage}>{message.isEmpty ? t('_fill_field') : message.errorMessage}</span>}

            {photos.length > 0 && (
              <div className={styles.sliderWrapper}>
                <p>{t('_public_photo_info')}</p>
                <Slider {...getSliderSettings()} className='photoSlider'>
                  {photos.map((photo, i) => {
                    const urlImg = URL.createObjectURL(photo);
                    return (<div key={`${i}_image`}>
                      <UiCheckbox id={photo.name} text={t('_public_photo')} onChange={() => {
                        setPublicPhoto((prevState) => ({...prevState, [photo.name]: !prevState[photo.name]}))
                      }} checked={publicPhoto[photo.name]}/>
                      <img src={urlImg} alt="" style={{width: '100%'}}/>
                    </div>)
                  })}
                </Slider>
              </div>
            )}
            {/*<p className={styles.subTitle}>{t('_need_help_photo_info')}</p>*/}
            <label htmlFor='files' className={styles.inputFiles}>
              <input id='files' type="file" multiple accept="image/*" onChange={({target}) => {
                setPublicPhoto([...target.files].reduce((acc, {name}) => ({...acc, [name]: false}), {}));
                setPhotos([...target.files]);
              }}/>
              <img src={imgUpload} alt=""/>
              {photos.length > 0 ? <span>{t('Chosen {count} file(s)', {count: photos.length})}</span> :
                <span>{t('Upload')}</span>}
            </label>


            <UiButton text={t('Submit')} color='primary' disabled={isDisable} onClick={handleSubmit}/>
          </div>
        </>
      }
    </div>
  )
}

export default INeedHelp
