import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import QRCode from "../QRCode/QRCode";
import ClipboardCopy from "../ClipboardCopy/ClipboardCopy";

import {copyTextToClipboard} from "../../utils/copyTextToClipboard";
import {toastMessage} from "../../utils/toastMessage";

import {cryptoWallets} from "../../constants/cryptoWallets";

import contentCopy from './img/content_copy.svg'

import styles from "./ShareOther.module.css";

const ShareOther = ({ showCryptoWallet = true }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const urlLink = `${window.location.origin}?ref=${user.reflink}${user.received ? `&donation=${user.id}` : ''}`;

  const handleCopy = (wallet) => () => {
    copyTextToClipboard(wallet)
      .then(() => {
        toastMessage('info', t('Copied!'))
      })
      .catch(e => {})
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.wrapperTitle}>{t('No way to help?')}</span>
      <div className={styles.wrapperInfo}>
          <span>
            <Trans i18nKey={'Send him an invitation or show him the code.'} components={{ br: <br /> }}>
              Send him an invitation or show him the code. <br/>
            </Trans>
          </span>
      </div>

      <div className={styles.container}>
        {user.reflink && <QRCode url={urlLink}/>}
        <ClipboardCopy text={urlLink} />
      </div>
      {showCryptoWallet &&
        <div className={styles.wallets}>
          <p className={styles.walletsTitle}>{t('You can make an anonymous cryptocurrency donation')}</p>
          {cryptoWallets.map(({name, wallet}) =>
            <div key={name} className={styles.walletItem}>
              <span className={styles.walletName}>{t('Wallet')} {name}</span>
              <div className={styles.walletWrap} onClick={handleCopy(wallet)}>
                <span>{wallet}</span>
                <img src={contentCopy} alt=""/>
              </div>
            </div>)}
        </div>
      }
    </div>
  )
}

export default ShareOther;
