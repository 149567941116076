import {useEffect, useState} from "react";
import ModalContext from "./ModalContext";
import Modal from "../../components/Modal/Modal";

const ModalProvider = ({children}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [closing, setClosing] = useState(false);

  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    document.body.style.overflow = isOpenModal ? 'hidden' : ''
  }, [isOpenModal]);

  const openModal = (modalConfig) => {
    setModalContent(modalConfig);
    setIsOpenModal(true);
    setClosing(true);
  }

  const closeModal = () => {
    setClosing(false);
    const closeTimeout = setTimeout(() => {
      setIsOpenModal(false);
      clearTimeout(closeTimeout);
    }, 300)
  }

  const handlesModalProvider = {
    openModal,
    closeModal,
    closing
  }

  return (
    <ModalContext.Provider value={handlesModalProvider}>
      {isOpenModal && <Modal {...modalContent} />}
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
