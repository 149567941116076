import {useContext, useState} from "react";
import cn from 'classnames'
import ModalContext from "../../context/Modal/ModalContext";

import styles from './Modal.module.css'
import UiButton from "../ui/UiButton/UiButton";
import {useTranslation} from "react-i18next";

const noop = () => {};

const Modal = ({ children, title, ok = 'ok', okSubmit = noop,  cancel, cancelSubmit = noop, externalClose = true, isCloseAfter = false }) => {
  const { closeModal, closing } = useContext(ModalContext);
  const { t } = useTranslation()

  const backdropClasses = cn(
    styles.backdrop,
    !closing ? styles.backdropHide : '',
  );

  const handleOk = () => {
    okSubmit();
    closeModal();
  }

  const handleCancel = () => {
    cancelSubmit();
    closeModal();
  }

  return (
    <div className={backdropClasses} onClick={externalClose ? closeModal : noop}>
      <div className={styles.modal} onClick={isCloseAfter ? noop : (e) => e.stopPropagation()}>
        {title && <div className={styles.modalHeader}>
          <h3>{title}</h3>
        </div>}
        <div className={styles.modalBody}>
          {children}
        </div>
        <div className={styles.modalFooter}>
          {ok && <UiButton text={t(ok)} onClick={handleOk}/>}
          {cancel && <UiButton text={cancel} color='cancel' onClick={handleCancel} />}
        </div>
      </div>
    </div>
  )
}

export default Modal;
